import Sidebar from "./components/Sidebar";
import Editor from "./Editor/Editor";

export default function App() {
  return (
    <div className="flex">
      <Sidebar />
      <div>
        <Editor />
      </div>
    </div>
  )
}