import { Controls, Colors } from "flume";

const numberPort = {
  type: "number",
  name: "number",
  label: "Number",
  color: Colors.orange,
  // hidePort: true,
  controls: [
    Controls.number({
      name: "number",
      label: "Number"
    })
  ]
};

export default numberPort;