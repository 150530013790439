const subtractNode = {
  type: "subtract",
  label: "Subtract",
  description: "Outputs summation of 2 numbers",
  initialWidth: 160,
  inputs: (ports) => [
    ports.number({
      name: "number1",
      label: "First number"
    }),
    ports.number({
      name: "number2",
      label: "Second number"
    })
  ],
  outputs: (ports) => [ports.number()]
};

export default subtractNode;