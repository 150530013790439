import { RootEngine } from "flume";
import config from "../Editor/config";

const resolvePorts = (portType, data) => {
  switch (portType) {
    case 'string':
      return data.string;
    case 'stringValue':
      return data.string;
    case 'number':
      return data.number;
    case 'numberValue':
      return data.number;
    case 'boolean':
      return data.boolen;
    default:
      return data
  }
}

const resolveNodes = (node, inputValues, nodeType, context) => {
  switch (node.type) {
    case 'string':
      return { string: inputValues.string };
    case 'result':
      console.log('got result');
      return inputValues;
    case 'number':
      return { number: inputValues.number };
    case 'add':
      return { number: inputValues.number1 + inputValues.number2 };
    case 'joinText':
      return { string: inputValues.string1 + inputValues.string2 };
    default:
      console.log('default');
      return 'default';
  }
}

const engine = new RootEngine(config, resolvePorts, resolveNodes);

export default engine;