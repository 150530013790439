import { Controls, Colors } from "flume";
import NumberInput from "../customeNodeComponents/NumberInput";

const customNumberInputPort = {
  type: "numberInput",
  name: "number",
  label: "Number",
  color: Colors.orange,
  controls: [
    Controls.custom({
      name: "number input",
      label: "Number",
      render: (data, onChange) => (
        <NumberInput />
      )
    })
  ]
};

export default customNumberInputPort;