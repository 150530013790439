const convertToNode = {
  type: "convertTo",
  label: "Convert",
  description: "Take a data type as input and convert it to other data type",
  inputs: (ports) => (data, connections, context) => {
    if (connections.inputs && connections.inputs.string && connections.inputs.string.length) {
      return [ports.string()]
    } else if (connections.inputs && connections.inputs.number && connections.inputs.number.length) {
      return [ports.number()]
    }
    // console.log(ports.string().noControls)
    return [
      ports.string(),
      ports.number()
    ];
  },
  outputs: (ports) => (data, connections) => {
    if (connections.inputs && connections.inputs.string && connections.inputs.string.length) {
      return [ports.number()]
    } else {
      return [ports.string()]
    }
  }
};

export default convertToNode;