const joinText = {
  type: "joinText",
  label: "Join Text",
  description: "Combines two strings of text into one string",
  initialWidth: 160,
  inputs: (ports) => [
    ports.string({
      name: "string1",
      label: "First text"
    }),
    ports.string({
      name: "string2",
      label: "Second text"
    })
  ],
  outputs: (ports) => [
    ports.string()
  ]
}

export default joinText;