import { useEffect, useState } from "react"
import add from '../examples/add.json';
import joinText from '../examples/joinText.json';
import twoLayerAdd from '../examples/twoLayerAdd.json';
import store from "../store/store";
import db from "../db";

export default function Sidebar() {
  const [exampleflows, setExampleFlows] = useState([]);
  const [flows, setFlows] = useState([]);

  useEffect(() => {
    store.subscribe(updateFlows);
  });

  useEffect(() => {
    getDBFlows();
  });

  const getDBFlows = async () => {
    const dbFlow = await db.flows.toArray();
    setFlows(dbFlow);
  }

  const updateFlows = () => {
    const state = store.getState();
    console.log('sidebar state', state);
    setFlows(state.flows.flows);
  }

  useEffect(() => {
    if (!exampleflows.length) {
      setExampleFlows(createExamples());
    }
  }, [exampleflows]);

  const createExamples = () => {
    return [
      {
        'name': 'Add',
        'nodes': add,
      },
      {
        'name': "Join Text",
        'nodes': joinText
      },
      {
        'name': '2 Layer Add',
        'nodes': twoLayerAdd
      }
    ]
  }

  const showExample = (nodes) => {
    console.log("show example", nodes);
    store.dispatch.displayNode.setDisplayNode(nodes);
  }

  return (
    <div className="w-96 p-3 sm:hidden lg:block">
      <div className="text-lg font-semibold">Workspace: New Workspace</div>
      <div className="divider mt-1 mb-1"></div>
      <div className="mt-3 font-bold text-lg">Example workflows</div>
      {
        exampleflows.map(flow => <div
          className="p-2 pl-0 
          hover:bg-gray-600 hover:rounded-md
          hover:border-2 border-blue-500 hover:cursor-pointer
          hover:font-bold"
          onClick={(_e) => showExample(flow.nodes)}
          key={flow.name}
        >{flow.name}</div>)
      }
      <div className="mt-3 font-bold text-lg">Your workflows</div>
      {
        !flows.length ? <div className="mt-3 text-sm font-bold italic">You don't have any workflow saved</div> :
          flows.map(flow => <div
            className="p-2 pl-0 
          hover:bg-gray-600 hover:rounded-md
          hover:border-2 border-blue-500 hover:cursor-pointer
          hover:font-bold"
            onClick={(_e) => showExample(flow.flow)}
            key={flow.name}
          >{flow.name}</div>)
      }
    </div>
  )
}