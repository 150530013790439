export const displayNode = {
  state: {
    displayNode: {}
  },
  reducers: {
    setDisplayNode(_state, payload) {
      return payload;
    }
  },
}

export const flows = {
  state: {
    flows: []
  },
  reducers: {
    addFlow(state, payload) {
      // console.log("state dsada", state.flows);
      // console.log("payload", payload);
      return {
        ...state,
        flows: payload
      }
    },
  }
}