import { Controls, Colors } from "flume";

const stringValuePort = {
  type: "stringValue",
  name: "string",
  label: "Text",
  color: Colors.green,
  hidePort: true,
  controls: [
    Controls.text({
      name: "string",
      label: "Text"
    })
  ]
};

export default stringValuePort;