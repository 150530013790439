import { Controls, Colors } from "flume";

const booleanPort = {
  type: "boolean",
  name: "boolean",
  label: "True/False",
  color: Colors.red,
  controls: [
    Controls.checkbox({
      name: "boolean",
      label: "True/False"
    })
  ]
};

export default booleanPort;