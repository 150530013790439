import { NodeEditor, useRootEngine } from "flume";
import config from "./config";
import React, { useEffect, useState } from "react";
import removeKeys from "./sanitize";
import execute from "../engine/pocEngine";
import store from "../store/store";
import engine from "../engine/engine";
import db from "../db";

function Editor() {
  const [nodes, setNodes] = React.useState();
  const [changingKeys, setChangingKeys] = React.useState(Math.random());
  const [result, setResult] = React.useState();
  const nodeEditor = React.useRef();
  const [saveName, setSaveName] = useState('New Flow');

  useEffect(() => {
    store.subscribe(updateEditor);
  }, [])

  const updateEditor = () => {
    const state = store.getState();
    console.log(state.displayNode.displayNode);
    console.log("update editor", state);
    if (state.displayNode) {
      setNodes(state.displayNode);
      setChangingKeys(Math.random());
    }
  }

  useEffect(() => {
    const nd = localStorage.getItem('nodeStructure');
    console.log("local");
    if (nd && !nodes) {
      setNodes(JSON.parse(nd));
      setChangingKeys(Math.random());
    }
  }, [])

  const saveNodes = async () => {
    let updatedNodes = nodeEditor.current.getNodes();
    updatedNodes = removeKeys(updatedNodes, []);
    localStorage.setItem('nodeStructure', JSON.stringify(updatedNodes));
    setNodes(updatedNodes);
    await db.flows.add({
      name: saveName,
      flow: updatedNodes
    });
    const flows = store.getState().flows.flows;
    console.log("editor flows", flows);
    flows.push({
      name: saveName,
      flow: updatedNodes
    })
    store.dispatch.flows.addFlow(flows);
    store.dispatch.displayNode.setDisplayNode(updatedNodes);
  };

  const executeNodes = () => {
    const n = nodeEditor.current.getNodes();
    let r = engine.resolveRootNode(n);
    console.log('result', r);
    setResult(r);
  }

  const clear = () => {
    setNodes({});
    setResult(null);
    setChangingKeys(Math.random());
    localStorage.removeItem('nodeStructure');
  }

  const changeToInput = () => {
    setSaveName(null)
  }

  return (
    <div className="flex-col items-end" >
      <div className="flex h-full p-3">
        {
          saveName ?
            <div onClick={changeToInput}>{saveName}</div>
            : <div className="flex">
              <input
                type="text"
                placeholder="Enter flow name"
                className="p-1 rounded-md"
                value={saveName}
                onKeyDown={(e) => e.key === "Enter" ? setSaveName(e.target.value) : null}
              />
            </div>
        }

      </div>
      <div style={{ width: "83vw", height: "80vh" }} className="rounded-md flex justify-end">
        <NodeEditor
          ref={nodeEditor}
          portTypes={config.portTypes}
          nodeTypes={config.nodeTypes}
          nodes={nodes}
          key={changingKeys}
          defaultNodes={[
            {
              type: "result",
              x: 0,
              y: 0
            }
          ]}
        />
      </div>
      <div className="flex justify-around items-center">
        <button className="mt-3 btn btn-primary rounded-md" onClick={executeNodes}>Execute Logic</button>
        <button className="mt-3 btn btn-secondary rounded-md" onClick={clear}>Clear Logic</button>
        <button className="mt-3 btn btn-accent rounded-md" onClick={saveNodes}>Save Logic</button>
        {
          result ?
            <div className="mt-3">
              {/* {JSON.stringify(result)} */}
              <div className="flex">
                <div className="rounded-lg rounded-r-none border-2 border-r-0 p-2 border-blue-600">{Object.keys(result)[0]}</div>
                <div className="rounded-lg rounded-l-none border-2 p-2 border-blue-600">{result[Object.keys(result)[0]]}</div>
              </div>
            </div> :
            <div className="">
              Result will appear here
            </div>
        }
      </div>
    </div>
  );
}


export default Editor;