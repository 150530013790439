import { FlumeConfig } from "flume";
import * as ports from '../ports';
import * as nodes from '../nodes';


const config = new FlumeConfig();
config
  .addPortType(ports.booleanPort)
  .addPortType(ports.numberPort)
  .addPortType(ports.stringPort)
  .addPortType(ports.dataTypePort)
  .addPortType(ports.customNumberInputPort)
  .addPortType(ports.numberValuePort)
  .addPortType(ports.stringValuePort)
  .addRootNodeType(nodes.resultNode)
  .addNodeType(nodes.textNode)
  .addNodeType(nodes.booleanNode)
  .addNodeType(nodes.numberNode)
  .addNodeType(nodes.addNode)
  .addNodeType(nodes.convertToNode)
  .addNodeType(nodes.subtractNode)
  .addNodeType(nodes.joinText)
  .addRootNodeType({
    type: "homepage",
    label: "Homepage",
    initialWidth: 170,
    inputs: (ports) => [
      ports.string({
        name: "title",
        label: "Title"
      }),
      ports.string({
        name: "description",
        label: "Description"
      }),
      ports.boolean({
        name: "showSignup",
        label: "Show Signup"
      }),
      ports.number({
        name: "copyrightYear",
        label: "Copyright Year"
      })
    ]
  })
  .addNodeType({
    type: "user",
    label: "User",
    description: "Outputs attributes of the current user",
    initialWidth: 130,
    outputs: (ports) => [
      ports.string({
        name: "firstName",
        label: "First Name"
      }),
      ports.string({
        name: "lastName",
        label: "Last Name"
      }),
      ports.boolean({
        name: "isLoggedIn",
        label: "Is Logged-In"
      }),
      ports.boolean({
        name: "isAdmin",
        label: "Is Admin"
      })
    ]
  })
  .addNodeType({
    type: "reverseBoolean",
    label: "Reverse True/False",
    description: "Reverses a true/false value",
    initialWidth: 140,
    inputs: (ports) => [ports.boolean()],
    outputs: (ports) => [ports.boolean()]
  });

export default config;
