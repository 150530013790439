const resultNode = {
  type: "result",
  label: "Result",
  description: "Stores Result",
  // initialWidth: 160,
  inputs: (ports) => (data, connections, context) => {
    if (connections.inputs && connections.inputs.string && connections.inputs.string.length) {
      return [ports.string()]
    } else if (connections.inputs && connections.inputs.number && connections.inputs.number.length) {
      return [ports.number()]
    }
    // console.log(ports.string().noControls)
    return [
      ports.string(),
      ports.number()
    ];
  },
  outputs: (ports) => []
};

export default resultNode;