const numberNode = {
  type: "number",
  label: "Number",
  description: "Outputs a numeric value",
  // initialWidth: 160,
  inputs: (ports) => (data, connections, context) => {
    return [ports.numberValue()]
  },
  outputs: (ports) => [ports.number()]
};

export default numberNode;