import { Controls, Colors } from "flume";

const dataTypePort = {
  type: "dataType",
  name: "dataType",
  label: "Input",
  color: Colors.blue,
  controls: [
    Controls.select({
      name: "dataType",
      label: "True/False",
      options: [
        { value: "number", label: "Number" },
        { value: "string", label: "String" },
      ]
    })
  ]
};

export default dataTypePort;